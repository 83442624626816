import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { trackCustomEvent, OutboundLink } from 'gatsby-plugin-google-analytics';

import { forSize } from '../styled-utils';
import logo from 'src/assets/app-icon.png';
import logoMonofocus from 'src/assets/monofocus-icon.png';
import logo1440 from 'src/assets/1440-icon.png';
import ChevronDown from 'src/assets/chevron-down.inline.svg';

const Outer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 960px;

  padding: 16px 0;

  ${forSize.tabletLandscapeUp`
    justify-content: space-between;
  `}
`;

const LeftOuter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const RightOuter = styled.div`
  position: relative;
  display: none;
  align-items: center;

  ${forSize.tabletLandscapeUp`
    display: flex;
  `}
`;

const Logo = styled.img`
  height: 32px;
  width: 32px;
`;

const Heading = styled.h1`
  margin: 0 12px;

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
`;

const HeadingLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const DropdownToggle = styled.button`
  width: 22px;
  height: 20px;
  padding: 0;

  background: ${(props) => (props.isHidden ? 'rgba(0, 113, 15, 0.08)' : 'rgba(0, 113, 15, 0.15)')};
  line-height: 0px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  appearance: none;
  transition: 0.2s ease-in;
`;

const Dropdown = styled.div`
  position: absolute;
  bottom: 0;
  left: -16px;

  display: flex;
  flex-flow: column nowrap;
  width: 235px; /* HACK: Otherwise dropdown the width is too small, ship it */

  transform: ${(props) => (props.isHidden ? 'translateY(calc(104% - 10px))' : 'translateY(104%)')};
  opacity: ${(props) => (props.isHidden ? '0' : '1')};
  pointer-events: ${(props) => (props.isHidden ? 'none' : 'all')};
  transition: 0.2s ease-in;

  overflow: hidden;
  background: #f0fff3;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
`;

const DropdownItem = styled(OutboundLink)`
  position: relative;

  display: flex;
  flex-flow: row;
  padding: 0 16px 0 16px;

  text-decoration: none;
  transition: 0.2s ease-in;

  &:after {
    content: '';

    position: absolute;
    left: 0;
    bottom: 0;

    display: block;
    width: 100%;
    height: 1px;

    background: rgba(142, 232, 160, 0.18);
    border-radius: 2px;
  }

  &:first-of-type {
    padding-top: 12px;
    padding-bottom: 14px;
  }

  &:last-of-type {
    padding-top: 14px;
    padding-bottom: 12px;
  }

  &:last-of-type:after {
    display: none;
  }

  &:hover {
    background: rgba(142, 232, 160, 0.18);
  }
`;

const DropdownItemContent = styled.div`
  display: flex;
  flex-flow: column;
  margin: 0 0 0 12px;
`;

const DropdownItemIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const DropdownItemTitle = styled.h5`
  margin: 0 0 2px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const DropdownItemSubtitle = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
`;

const NavLink = styled(Link)`
  padding: 8px 16px;
  margin: 0 4px;

  color: #000000;
  background: ${(props) => (props.isActive ? '#8ee8a0' : 'none')};

  line-height: 20px;
  text-decoration: none;

  border-radius: 8px;
  transition: 0.2s ease-in;

  &:hover {
    background: #8ee8a0;
  }
`;

const DownloadLink = styled.a`
  padding: 8px 16px;
  margin: 0 0 0 16px;

  color: #ffffff;
  background: #5bca49;

  line-height: 20px;
  font-weight: 500;
  text-decoration: none;

  border-radius: 8px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), inset 0px 4px 10px rgba(255, 255, 255, 0.1);
  transition: 0.2s ease-in;

  &:hover {
    background: #50d03b;
    box-shadow: 0 5px 20px 2px rgb(80, 208, 59, 0.3);
  }
`;

export default () => {
  const [isDropdownHidden, setDropdownHidden] = useState(true);

  return (
    <Outer>
      <LeftOuter>
        <HeadingLink to="/">
          <Logo src={logo} alt="GitPigeon Logo" />
          <Heading>GitPigeon</Heading>
        </HeadingLink>

        <DropdownToggle
          title={isDropdownHidden ? 'Show other apps' : 'Hide other apps'}
          isHidden={isDropdownHidden}
          onClick={(e) => setDropdownHidden(!isDropdownHidden)}
        >
          <ChevronDown style={{ transform: isDropdownHidden ? '' : 'scaleY(-1)' }} />
        </DropdownToggle>

        <Dropdown isHidden={isDropdownHidden} aria-hidden={isDropdownHidden}>
          <DropdownItem href="https://monofocus.app/?utm_source=gitpigeon&utm_medium=landing&utm_campaign=navbar">
            <DropdownItemIcon src={logoMonofocus} />
            <DropdownItemContent>
              <DropdownItemTitle>MonoFocus</DropdownItemTitle>
              <DropdownItemSubtitle>Focus on one task at a time.</DropdownItemSubtitle>
            </DropdownItemContent>
          </DropdownItem>

          <DropdownItem href="https://1440app.com/?utm_source=gitpigeon&utm_medium=landing&utm_campaign=navbar">
            <DropdownItemIcon src={logo1440} />
            <DropdownItemContent>
              <DropdownItemTitle>1440</DropdownItemTitle>
              <DropdownItemSubtitle>Make every minute count.</DropdownItemSubtitle>
            </DropdownItemContent>
          </DropdownItem>
        </Dropdown>
      </LeftOuter>

      <RightOuter>
        <NavLink to="/#faq">FAQ</NavLink>
        <NavLink to="/privacy">Privacy</NavLink>
        <DownloadLink
          href="https://updates.gitpigeon.com/GitPigeon.latest.dmg"
          onClick={() => {
            trackCustomEvent({
              category: 'Button Clicks',
              action: 'Click Download App in Navigation Bar',
              label: 'Download App',
            });
          }}
        >
          Download for macOS
        </DownloadLink>
      </RightOuter>
    </Outer>
  );
};
