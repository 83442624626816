import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import 'src/index.scss';
import { H1 } from 'src/components/base';
import Layout from 'src/components/layout';
import Content from 'src/components/content';
import NavBar from 'src/components/navBar';

export default function Template({ data }) {
  const {
    markdownRemark: { frontmatter, html },
  } = data;

  return (
    <Layout>
      <Content>
        <NavBar />
        <PageOuter>
          <H1>{frontmatter.title}</H1>
          <PageInner dangerouslySetInnerHTML={{ __html: html }} />
        </PageOuter>
      </Content>
    </Layout>
  );
}

const PageOuter = styled.article`
  margin: 64px 0;
`;

const PageInner = styled.div`
  margin: 16px 0;

  line-height: 1.5;

  h2 {
    margin: 32px 0 4px 0;
    font-size: 28px;
    font-weight: 500;
  }

  h3 {
    margin: 24px 0 4px 0;
    font-size: 20px;
    font-weight: 700;
  }

  p {
    margin: 0 0 16px 0;
  }
`;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;
